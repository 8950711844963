export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは1月より%d点上がりました。年度末で忙しい3月もこの調子で！<br>\n3月のテーマは、<b>❝新年度に向けて自分を整える❞</b>​です。春先は寒暖差や気圧変動が大きく、疲れやだるさを感じやすいです。自分の身体の状態を知り、2024年度に向けて、体調や生活習慣を整えていきましょう。\n\n",
      "down": "生活習慣スコアは1月より%d点下がりました。食事・運動・睡眠など、どの項目に変化がありましたか？<br>\n3月のテーマは、<b>❝新年度に向けて自分を整える❞</b>​です。春先は寒暖差や気圧変動が大きく、疲れやだるさを感じやすいです。自分の身体の状態を知り、2024年度に向けて、体調や生活習慣を整えていきましょう。\n\n",
      "equal100": "生活習慣スコアは1月に引き続き%満点%です。大変素晴らしいです！<br>\n3月のテーマは、<b>❝新年度に向けて自分を整える❞</b>​です。春先は寒暖差や気圧変動が大きく、疲れやだるさを感じやすいです。自分の身体の状態を知り、2024年度に向けて、体調や生活習慣を整えていきましょう。\n\n",
      "equal": "生活習慣スコアは1月と同じ点数です。<br>\n3月のテーマは、<b>❝新年度に向けて自分を整える❞</b>​です。春先は寒暖差や気圧変動が大きく、疲れやだるさを感じやすいです。自分の身体の状態を知り、2024年度に向けて、体調や生活習慣を整えていきましょう。\n\n"
    },
    "steps": {
      "up": "歩数スコアは1月より%d点上がりました。<br>\n寒い時期もよく歩けていますね！ 歩くことはストレス解消にも役立ちますよ。その調子！\n\n",
      "down": "歩数スコアは1月より%d点下がりました<br>\n春の訪れを感じる季節ですね。暖かい日差しを感じながら、散歩するのも気持ちが良いですよ♪\n\n",
      "equal": "歩数スコアは1月と同じ点数です。<br>\n春の訪れを感じる季節ですね。暖かい日差しを感じながら、散歩するのも気持ちが良いですよ♪\n\n"
    },
    "exercise": {
      "up": "運動のスコアは1月より%d点上がりました。<br>\n活動的になる春に向けて、そろそろ準備運動！<a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">10minutes-break</a>の動画を見ながら<b>10分間</b>だけ身体を動かしてみませんか？\n\n",
      "down": "運動のスコアは1月より%d点下がりました。<br>\n活動的になる春に向けて、そろそろ準備運動！<a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">10minutes-break</a>の動画を見ながら<b>10分間</b>だけ身体を動かしてみませんか？\n\n",
      "equal": "運動のスコアは1月と同じ点数です。<br>\n活動的になる春に向けて、そろそろ準備運動！<a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">10minutes-break</a>の動画を見ながら<b>10分間</b>だけ身体を動かしてみませんか？\n\n"
    },
    "meal": {
      "up": "食事のスコアは1月より%d点上がりました。<br>\nゆっくり食べると、脳にある満腹中枢が刺激され、満腹感を感じることができます。1食15分以上かけて、食べることを意識しましょう。\n\n",
      "down": "食事のスコアは1月より%d点下がりました。<br>\nゆっくり食べると、脳にある満腹中枢が刺激され、満腹感を感じることができます。1食15分以上かけて、食べることを意識しましょう。\n\n",
      "equal": "食事のスコアは1月と同じ点数です。<br>\nゆっくり食べると、脳にある満腹中枢が刺激され、満腹感を感じることができます。1食15分以上かけて、食べることを意識しましょう。\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは1月より%d点上がりました。<br>\n歓送迎会が多い季節です。<a href=\"https://go.andwell.jp/library/eab366f0-9b3d-11ee-ad21-0a74c02d2ee3\">＜こちらのコラム＞</a>では、お店やお酒の選び方などを意識をして、急激な体重増加を防ぐ方法をお伝え！\n\n",
      "down": "飲酒のスコアは1月より%d点下がりました。<br>\n歓送迎会が多い季節です。<a href=\"https://go.andwell.jp/library/eab366f0-9b3d-11ee-ad21-0a74c02d2ee3\">＜こちらのコラム＞</a>では、お店やお酒の選び方などを意識をして、急激な体重増加を防ぐ方法をお伝え！\n\n",
      "equal": "飲酒のスコアは1月と同じ点数です。<br>\n歓送迎会が多い季節です。<a href=\"https://go.andwell.jp/library/eab366f0-9b3d-11ee-ad21-0a74c02d2ee3\">＜こちらのコラム＞</a>では、お店やお酒の選び方などを意識をして、急激な体重増加を防ぐ方法をお伝え！\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは1月より%d点上がりました。<br>\n3月18日は<b>「春の睡眠の日」</b>。朝食をしっかり食べて、適度な運動をして、眠りと目覚めのメリハリをつけましょう。\n\n",
      "down": "睡眠のスコアは1月より%d点下がりました。<br>\n3月18日は<b>「春の睡眠の日」</b>。朝食をしっかり食べて、適度な運動をして、眠りと目覚めのメリハリをつけましょう。\n\n",
      "equal": "睡眠のスコア1月と同じ点数です。<br>\n3月18日は<b>「春の睡眠の日」</b>。朝食をしっかり食べて、適度な運動をして、眠りと目覚めのメリハリをつけましょう。\n\n"
    },
    "stress": {
      "up": "ストレスのスコア1月より%d点上がりました。<br>\n人は誰でも変化に対していつもと同じ状態に維持しようとする傾向があります。リラックスしたい時は、「フーッ」と息を吐いてみましょう。\n\n",
      "down": "ストレスのスコアは1月より%d点下がりました。<br>\n人は誰でも変化に対していつもと同じ状態に維持しようとする傾向があります。リラックスしたい時は、「フーッ」と息を吐いてみましょう。\n\n",
      "equal": "ストレスのスコアは1月と同じ点数です。<br>\n人は誰でも変化に対していつもと同じ状態に維持しようとする傾向があります。リラックスしたい時は、「フーッ」と息を吐いてみましょう。\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your Lifestyle Score has gone up by %d points since January.Keep it up in March, the busiest month of the year!<br>\nThe theme for March is \"<b>Prepare Yourself for the New Year.</b>\" In early spring, there are large differences in temperature and pressure fluctuations, and it is easy to feel tired and sluggish. Let's learn about the condition of your body and adjust your physical condition and lifestyle for the 2024 fiscal year.\n\n",
      "down": "Your Lifestyle Score has dropped by %d points since January.Which of the following changed: diet, exercise, sleep, etc.?<br>\nThe theme for March is \"<b>Prepare Yourself for the New Year.</b>\" In early spring, there are large differences in temperature and pressure fluctuations, and it is easy to feel tired and sluggish. Let's learn about the condition of your body and adjust your physical condition and lifestyle for the 2024 fiscal year.\n\n",
      "equal100": "Your Lifestyle score is perfect, just as it was in January. That's a perfect score for consecutive months! Very impressive!<br>\nThe theme for March is \"<b>Prepare Yourself for the New Year.</b>\" In early spring, there are large differences in temperature and pressure fluctuations, and it is easy to feel tired and sluggish. Let's learn about the condition of your body and adjust your physical condition and lifestyle for the 2024 fiscal year.\n\n",
      "equal": "Your Lifestyle score is the same as it was in January.<br>\nThe theme for March is \"<b>Prepare Yourself for the New Year.</b>\" In early spring, there are large differences in temperature and pressure fluctuations, and it is easy to feel tired and sluggish. Let's learn about the condition of your body and adjust your physical condition and lifestyle for the 2024 fiscal year.\n\n"
    },
    "steps": {
      "up": "Your steps score has gone up %d pts since January.<br>\nYou walk a lot, even in cold weather! Walking helps relieve stress, too. Keep it up!\n\n",
      "down": "Your steps score has gone down %d pts since January. <br>\nWe can feel the arrival of spring. It is nice to take a walk while feeling the warm sunshine♪\n\n",
      "equal": "Your steps score is the same as January. <br>\nWe can feel the arrival of spring. It is nice to take a walk while feeling the warm sunshine♪\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d pts since January.<br>\nIt's time to get ready for spring, the perfect season to be more active! Watch the <a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">10minutes-break</a> video to help you get started.\n\n",
      "down": "Your exercise score has gone down %d pts since January.<br>\nIt's time to get ready for spring, the perfect season to be more active! Watch the <a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">10minutes-break</a> video to help you get started.\n\n",
      "equal": "Your exercise score is the same as January.<br>\nIt's time to get ready for spring, the perfect season to be more active! Watch the <a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">10minutes-break</a> video to help you get started.\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d pts since January.<br>\nEating slowly stimulates the satiety center in the brain, making you feel full. Remember to take at least 15 minutes to eat each meal.\n\n",
      "down": "Your food score has gone down %d pts since January.<br>\nEating slowly stimulates the satiety center in the brain, making you feel full. Remember to take at least 15 minutes to eat each meal.\n\n",
      "equal": "Your food score is the same as January.<br>\nEating slowly stimulates the satiety center in the brain, making you feel full. Remember to take at least 15 minutes to eat each meal.\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d pts since January.<br>\nIt's the season for many welcome and farewell parties. <a href=\"https://go.andwell.jp/library/eab366f0-9b3d-11ee-ad21-0a74c02d2ee3\">In this column</a>, we will explain how to prevent sudden weight gain by being conscious of the choice of restaurant and alcohol!\n\n",
      "down": "Your alcohol score has gone down %d pts since January.<br>\nIt's the season for many welcome and farewell parties. <a href=\"https://go.andwell.jp/library/eab366f0-9b3d-11ee-ad21-0a74c02d2ee3\">In this column</a>, we will explain how to prevent sudden weight gain by being conscious of the choice of restaurant and alcohol!\n\n",
      "equal": "Your alcohol score is the same as January.<br>\nIt's the season for many welcome and farewell parties. <a href=\"https://go.andwell.jp/library/eab366f0-9b3d-11ee-ad21-0a74c02d2ee3\">In this column</a>, we will explain how to prevent sudden weight gain by being conscious of the choice of restaurant and alcohol!\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d pts since January.<br>\nMarch 18 is \"<b>Spring Sleep Day.</b>\" Let's start the day off right with a good night's rest, a complete breakfast, and a bit of exercise.\n\n",
      "down": "Your sleep score has gone down %d pts since January.<br>\nMarch 18 is \"<b>Spring Sleep Day.</b>\" Let's start the day off right with a good night's rest, a complete breakfast, and a bit of exercise.\n\n",
      "equal": "Your sleep score is the same as January.<br>\nMarch 18 is \"<b>Spring Sleep Day.</b>\" Let's start the day off right with a good night's rest, a complete breakfast, and a bit of exercise.\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d pts since January.<br>\nWe all have a tendency to try to maintain the same state of mind as usual in response to change. Take a deep breath and exhale slowly to help you relax.\n\n",
      "down": "Your stress score has gone down %d pts since January.<br>\nWe all have a tendency to try to maintain the same state of mind as usual in response to change. Take a deep breath and exhale slowly to help you relax.\n\n",
      "equal": "Your stress score is the same as January.<br>\nWe all have a tendency to try to maintain the same state of mind as usual in response to change. Take a deep breath and exhale slowly to help you relax.\n\n"
    }
  }
}